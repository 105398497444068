<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="prepaidKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Prepaid
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const formTitle = 'Prepaid'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      prepaidKits: [
        {
          icon: 'system_update',
          path: '/prepaid_topup',
          title: 'Topup Prepaid number',
          desc: 'Use e-recharge to do topup for subscriber.',
        },
      ],
    }
  },
}
</script>
